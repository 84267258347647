import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import {
	ArrowLink,
	CancelIcon,
	ChevronDownIcon,
	ChevronUpIcon,
	ITheme,
	Responsive,
	themed,
} from '@m2/blueprint-ui';
import WarningIcon from '../../../assets/icon-warning.svg?reference';
import { useNewsAlertStore } from './useNewsAlertStore';
import {
	NAVIGATION_PADDING_L,
	NAVIGATION_PADDING_M,
	NAVIGATION_PADDING_S,
	NAVIGATION_PADDING_XL,
} from '../header/components';

export const StyledAlertBanner = styled.div<{}, ITheme>`
	background: ${themed(({ alertBanner }) => alertBanner.background)};
	padding: 18px 0;
	padding-left: ${NAVIGATION_PADDING_XL};
	padding-right: ${NAVIGATION_PADDING_XL};

	p:last-of-type {
		margin-top: 16px;
		margin-bottom: 8px;
		color: white;
		margin-left: 80px;
		width: fit-content;

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			margin-left: 75px;
		}
	}

	@media (max-width: 1300px) {
		padding-left: ${NAVIGATION_PADDING_L};
		padding-right: ${NAVIGATION_PADDING_L};
	}

	@media (max-width: 1100px) {
		padding-left: ${NAVIGATION_PADDING_M};
		padding-right: ${NAVIGATION_PADDING_M};
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding-left: ${NAVIGATION_PADDING_S};
		padding-right: ${NAVIGATION_PADDING_S};
	}
`;

export const StyledAlertBannerHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
`;

export const StyledAlertBannerCloseButton = styled.div<{}, ITheme>`
	flex-shrink: 0;
	cursor: pointer;
	display: flex;
	align-self: center;
	justify-self: center;

	span {
		color: white;
		font-size: 16px;
		font-weight: bold;
	}

	svg {
		display: none;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		span {
			display: none;
		}
		svg {
			display: flex;
			width: 24px;
			height: 24px;
		}
	}
`;

export const StyledIconWrapper = styled.div`
	display: none;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: flex;
		height: 24px;
		width: 24px;
	}
`;

export const StyledChevron = styled.div`
	display: flex;
	transform: translateY(3px);
	cursor: pointer;
	svg {
		width: 24px;
		height: 24px;
	}
`;

export const StyledHeading = styled.div`
	display: flex;
	margin-right: auto;
	justify-content: center;
	align-items: center;
`;

export const StyledChildrenWrapper = styled.div`
	p {
		color: white;
		margin-top: 8px;
		margin-bottom: 0;
		margin-left: 80px;
		width: 300px;
	}
`;

export const StyledAlertBannerTitle = styled.span<{}, ITheme>`
	color: white;
	font-size: 24px;
	margin-left: 40px;
	margin-right: 8px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		font-size: 18px;
		max-width: 300px;
	}
`;

export const StyledWarningIconWrapper = styled.div`
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translateY(2px);

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		height: 32px;
		width: 32px;
		object-fit: contain;
	}
`;

const StyledArrowLink = styled(ArrowLink)`
	margin-top: 16px;
	margin-bottom: 0;
	margin-left: 80px;

	svg {
		margin-top: 3px;
		margin-left: 6px;
	}
`;
interface INewsAlertBannerProps {
	id: string;
	title: string;
	body: string;
	closeLabel?: string;
	link?: string;
}

export const NewsAlertBanner = observer(
	({ title, body, closeLabel, id, link }: INewsAlertBannerProps) => {
		const [isUnfolded, setIsUnfolded] = useState(false);

		const { newsAlertStore } = useNewsAlertStore();

		useEffect(() => {
			newsAlertStore.init();
		}, []);

		if (!newsAlertStore.getShouldShow(id)) {
			return null;
		}

		return (
			<StyledAlertBanner role="alert">
				<StyledAlertBannerHeader>
					<StyledHeading>
						<StyledWarningIconWrapper>
							<img src={WarningIcon} alt="" />
						</StyledWarningIconWrapper>
						<StyledAlertBannerTitle>{title}</StyledAlertBannerTitle>
						<StyledChevron onClick={() => setIsUnfolded(!isUnfolded)}>
							{!isUnfolded && <ChevronDownIcon variation="inverted" />}
							{isUnfolded && <ChevronUpIcon variation="inverted" />}
						</StyledChevron>
					</StyledHeading>
					<StyledAlertBannerCloseButton
						onClick={() => newsAlertStore.dismiss(id)}
					>
						{closeLabel && <span>{closeLabel}</span>}
						<StyledIconWrapper>
							<CancelIcon variation="inverted" />
						</StyledIconWrapper>
					</StyledAlertBannerCloseButton>
				</StyledAlertBannerHeader>
				{isUnfolded && (
					<StyledChildrenWrapper>
						<p>{body}</p>

						{link && (
							<>
								<StyledArrowLink inverted href={link}>
									Read more
								</StyledArrowLink>
							</>
						)}
					</StyledChildrenWrapper>
				)}
			</StyledAlertBanner>
		);
	},
);

import localforage from 'localforage';
import log from 'loglevel';
import { observable, action, runInAction, makeObservable } from 'mobx';

const STORAGE_KEY = 'DismissedNewsAlerts';

export class NewsAlertStore {
	protected _dismissedAlerts: string[] = [];

	protected _isInitialized = false;

	constructor() {
		makeObservable<NewsAlertStore, '_dismissedAlerts' | '_isInitialized'>(
			this,
			{
				_dismissedAlerts: observable,
				_isInitialized: observable,
				dismiss: action,
			},
		);
	}

	async init() {
		if (this._isInitialized) {
			return;
		}

		localforage.config({
			driver: localforage.LOCALSTORAGE,
			name: 'dfw',
		});

		const dismissedAlerts = await this.getDismissedAlertsFromStorage();

		runInAction(() => {
			this._dismissedAlerts = Array.isArray(dismissedAlerts)
				? dismissedAlerts
				: [];
			this._isInitialized = true;
		});
	}

	public getShouldShow(id: string) {
		return this._isInitialized === true && !this._dismissedAlerts.includes(id);
	}

	public dismiss(id: string) {
		this._dismissedAlerts.push(id);
		this.setDismissedAlertsFromStorage(this._dismissedAlerts);
	}

	private getDismissedAlertsFromStorage() {
		return localforage.getItem<string[]>(STORAGE_KEY).catch((error) => {
			log.warn('Failed to read value from storage', error);
		});
	}

	private setDismissedAlertsFromStorage(ids: string[]) {
		localforage.setItem(STORAGE_KEY, ids).catch((error) => {
			log.warn('Failed to write value to storage', error);
		});
	}
}

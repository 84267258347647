import React from 'react';
import { NewsAlertStore } from './NewsAlertStore';

const newsAlertStore = new NewsAlertStore();

const storesContext = React.createContext({
	newsAlertStore,
});

export const useNewsAlertStore = () => React.useContext(storesContext);
